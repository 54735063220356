import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {FetchWithAuth} from 'digimaker-ui/util';
import './ViewContent.css';
import { RenderContent } from './RenderContent';
import { ExpandableLine } from './shared/Common';

export default class ViewContent extends React.Component<RouteComponentProps, {data:any}> {
    constructor(props){
        super(props);
        this.state = {data:''};
    }


    componentDidMount(){
        this.fetchData();
    }

    componentDidUpdate(prevProps){
        if( prevProps.location.pathname != this.props.location.pathname ){
            this.fetchData();            
        }
    }

    async fetchData(){
        let path = process.env.REACT_APP_CONTENT_PREFIX + this.props.location.pathname;
        let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/get?path='+path);
        if( data.error === false ){
            let folder = data.data;
            this.setState({data: folder});            
        }
    }   

    render(){

        let content = this.state.data;
        return <div className="content-wrapper">
            {content.content_type=='folder'&&content.module&&<div>
                    <RenderContent mode='module' params={{module:content.module}} content={content} />
            </div>}            

            {content.content_type=='article'&&<RenderContent mode='full' content={content} />}           
        </div>
    }
}