import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Util } from '../../util/Util';

export const FullArticle = (props:{content:any})=>{
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div>
        {props.content.coverimage&&<div>
            <img className="width-full" src={process.env.REACT_APP_ASSET_URL+'/'+props.content.coverimage} />
        </div>}
        <h2>{props.content.name}</h2>
        <br />
        <div className="content">
            <div style={{fontWeight:'bold'}} dangerouslySetInnerHTML={{__html: props.content.summary}}></div>
            <br />
            <div dangerouslySetInnerHTML={{__html: props.content.body}}></div>
        </div>
    </div>
}