var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import EthTableGroup from './EthTableGroup';
import Preferance from './Preferance';

var Custom =
/** @class */
function (_super) {
  __extends(Custom, _super);

  function Custom(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {};
    return _this;
  }

  Custom.prototype.render = function () {
    var definition = this.props.definition;
    var identifier = definition.identifier;

    if (identifier == 'step3_a1') {
      return React.createElement(EthTableGroup, {
        definition: this.props.definition,
        validation: this.props.validation,
        data: this.props.data,
        mode: this.props.mode,
        formdata: this.props.formdata,
        formValidation: this.props.formValidation
      });
    } else if (identifier == 'indicator') {
      return '';
    } else if (identifier == 'preferance') {
      return React.createElement(Preferance, {
        definition: this.props.definition,
        mode: this.props.mode,
        validation: this.props.validation,
        data: this.props.data,
        formdata: this.props.formdata
      });
    } else {
      if (definition.parameters && definition.parameters.output) {
        return React.createElement("div", {
          className: "field"
        }, definition.name, React.createElement("i", {
          className: "icon-info",
          "data-tip": definition.description
        }));
      }

      return React.createElement("div", null, "Unknown custom field ", this.props.definition.identifier);
    }
  };

  return Custom;
}(React.Component);

export default Custom;