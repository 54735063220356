var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import * as React from 'react';
import i18n from '../i18n/i18n'; // @ts-ignore

import FileUpload from 'digimaker-ui/FileUpload';
import './eth_fieldtype.css';
import ReactTooltip from 'react-tooltip';
import Moment from 'react-moment';
import { FetchWithAuth } from 'digimaker-ui/util';
import { EthContext } from '../EthContext';

var EthDocumentList =
/** @class */
function (_super) {
  __extends(EthDocumentList, _super);

  function EthDocumentList(props) {
    var _this = _super.call(this, props) || this;

    var data = props.data ? props.data : [{
      primary: '1',
      file: '',
      name: ''
    }];

    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    _this.state = {
      data: data,
      documentCategories: []
    };
    return _this;
  }

  EthDocumentList.prototype.componentDidMount = function () {
    this.fetchCategory();
  };

  EthDocumentList.prototype.fetchCategory = function () {
    return __awaiter(this, void 0, void 0, function () {
      var data, list;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/setting/get')];

          case 1:
            data = _a.sent();
            list = data.documentCategory;
            this.setState({
              documentCategories: list
            });
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  EthDocumentList.prototype.onUploaded = function (index, file) {
    var data = this.state.data;
    data[index]['file'] = file.nameUploaded;
    var name = file.name;
    data[index]['name'] = name.substring(0, name.lastIndexOf('.'));
    data[index]['time'] = Math.floor(Date.now() / 1000);
    this.setState({
      data: data
    });
  };

  EthDocumentList.prototype.addRow = function () {
    var data = this.state.data;
    data.push({
      primary: '0',
      file: '',
      name: ''
    });
    this.setState({
      data: data
    });
  };

  EthDocumentList.prototype.removeRow = function (e, i) {
    e.preventDefault();
    var data = this.state.data;
    delete data[i];
    this.setState({
      data: data
    });
  };

  EthDocumentList.prototype.changePrimary = function (i) {
    var data = this.state.data;
    data.map(function (value, j) {
      if (i == j) {
        data[i]['primary'] = '1';
      } else {
        data[j]['primary'] = '0';
      }
    });
    this.setState({
      data: data
    });
  };

  EthDocumentList.prototype.renderRows = function () {
    var _this = this;

    var isEnglish = this.context.language == 'eng-GB';
    var isViewMode = this.props.mode == 'view';

    if (this.state.data.length == 0) {
      return '';
    }

    if (this.state.documentCategories.length == 0) {
      return '';
    }

    return this.state.data.map(function (row, i) {
      return row && React.createElement("tr", null, React.createElement("td", null, React.createElement("input", {
        type: "radio",
        disabled: isViewMode || !row.file,
        checked: row.primary == '1' ? true : false,
        onChange: function onChange() {
          _this.changePrimary(i);
        },
        name: _this.props.definition.identifier + "[" + i + "][primary]",
        value: "1"
      })), React.createElement("td", null, !isViewMode && React.createElement(FileUpload, {
        name: _this.props.definition.identifier + "[" + i + "][file]",
        service: "form",
        format: ".doc,.docx,.xls,.xlsx,.pdf,.xlsm",
        multi: false,
        value: row.file,
        onSuccess: function onSuccess(file) {
          _this.onUploaded(i, file);
        }
      }), row.file && React.createElement("div", {
        className: "existing-name"
      }, !isViewMode && row.name, isViewMode && React.createElement("a", {
        target: "_blank",
        href: '/var/upload_temp/' + row.file
      }, row.name)), row.time && React.createElement("span", {
        style: {
          color: '#333333',
          fontStyle: 'italic',
          fontSize: '12px'
        }
      }, i18n.t("Uploaded on"), ": ", React.createElement(Moment, {
        format: 'DD.MM.YYYY HH:mm',
        unix: true
      }, row.time))), React.createElement("td", null, React.createElement("input", {
        type: "text",
        disabled: isViewMode || !row.file,
        className: "form-control document-name",
        name: _this.props.definition.identifier + "[" + i + "][name]",
        defaultValue: row.name
      })), React.createElement("td", null, React.createElement("select", {
        disabled: isViewMode,
        className: "document-category form-control",
        name: _this.props.definition.identifier + "[" + i + "][category]",
        defaultValue: row.category ? row.category : ""
      }, React.createElement("option", {
        value: ""
      }, i18n.t("Select")), _this.state.documentCategories.map(function (item) {
        return React.createElement("option", {
          value: item.id
        }, isEnglish ? item['_eng'] : item.text);
      })), !isViewMode && React.createElement("input", {
        type: "hidden",
        name: _this.props.definition.identifier + "[" + i + "][time]",
        defaultValue: row.time
      })), React.createElement("td", null, React.createElement("input", {
        type: "text",
        className: "form-control",
        disabled: isViewMode,
        name: _this.props.definition.identifier + "[" + i + "][comment]",
        defaultValue: row.comment
      })), React.createElement("td", null, !isViewMode && React.createElement("a", {
        href: "",
        title: "Remove",
        onClick: function onClick(e) {
          _this.removeRow(e, i);
        }
      }, React.createElement("i", {
        className: "far fa-trash-alt"
      }))));
    });
  };

  EthDocumentList.prototype.render = function () {
    var _this = this;

    var isViewMode = this.props.mode == 'view';
    return React.createElement("div", {
      className: 'field ' + this.props.definition.type + ' ' + (this.props.definition.required ? 'required' : '') + (this.props.validation == '1' ? ' result-required' : '')
    }, React.createElement("label", null, this.props.definition.name, this.props.definition.description && React.createElement("i", {
      className: "icon-info",
      "data-for": this.props.definition.identifier + "-description",
      "data-tip": ""
    }), ":"), this.props.definition.description && React.createElement(ReactTooltip, {
      id: this.props.definition.identifier + '-description',
      html: true,
      effect: "solid",
      place: "right",
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, this.props.definition.description), React.createElement("div", {
      className: "description"
    }, "(word, excel, pdf)"), React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, i18n.t("Primary")), React.createElement("th", null, i18n.t("File")), React.createElement("th", null, i18n.t("Name")), React.createElement("th", null, i18n.t("Category"), React.createElement("span", {
      className: 'category-required'
    }, "*")), React.createElement("th", null, i18n.t("Comment")), React.createElement("th", null))), React.createElement("tbody", null, this.renderRows())), !isViewMode && React.createElement("div", {
      className: "block"
    }, React.createElement("input", {
      type: "button",
      className: "btn btn-sm btn-primary",
      onClick: function onClick() {
        _this.addRow();
      },
      value: i18n.t("Add document").toString()
    })));
  };

  return EthDocumentList;
}(React.Component);

export default EthDocumentList;
EthDocumentList.contextType = EthContext;