
/*
* This is to initialize all.
* This includes:
* - field types.
*/

// @ts-ignore
import FieldRegister from 'digimaker-ui/FieldRegister'
import EthDocumentList from 'eth-common/fieldtype/EthDocumentList';
import EthTableList from 'eth-common/fieldtype/EthTableList';
import OutputH from 'eth-common/fieldtype/OutputH';
import Preview from 'eth-common/fieldtype/Preview';
import Custom from 'eth-common/fieldtype/Custom';
import Richtext from 'eth-common/fieldtype/Richtext';
import SupplychainEmployeeAverage from './SupplychainEmployeeAverage';

import Image from 'eth-common/fieldtype/Image';

FieldRegister.registerComponent('preview', Preview);
FieldRegister.registerComponent('image', Image);
FieldRegister.registerComponent('text', Richtext);
FieldRegister.registerComponent('output_h', OutputH);
FieldRegister.registerComponent('eth_documentlist', EthDocumentList);
FieldRegister.registerComponent('eth_tablelist', EthTableList);
FieldRegister.registerComponent('report/supplychain_employee_average', SupplychainEmployeeAverage);
FieldRegister.registerComponent('report/step3_a1', Custom);
FieldRegister.registerComponent('report/indicator', Custom);
FieldRegister.registerComponent('report_public_sector/step3_a1', Custom);
FieldRegister.registerComponent('user/preferance', Custom);
