var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import './eth_fieldtype.css';

var OutputH =
/** @class */
function (_super) {
  __extends(OutputH, _super);

  function OutputH(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {};
    return _this;
  }

  OutputH.prototype.render = function () {
    var def = this.props.definition;
    var identifier = def.identifier;
    var parameters = def.parameters;
    var className = parameters && parameters['class'] ? parameters['class'] : '';
    return React.createElement("div", {
      className: "field " + def.type + " field-" + identifier + ' ' + className
    }, React.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: def.name
      }
    }), def.description && React.createElement("i", {
      className: "icon-info",
      "data-for": def.identifier + '-description',
      "data-tip": ""
    }), def.description && React.createElement(ReactTooltip, {
      id: def.identifier + '-description',
      html: true,
      effect: "solid",
      place: "right",
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, def.description));
  };

  return OutputH;
}(React.Component);

export default OutputH;