var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import Select from 'react-select';

var SelectYear =
/** @class */
function (_super) {
  __extends(SelectYear, _super);

  function SelectYear() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  SelectYear.prototype.render = function () {
    var _this = this;

    var years = [];
    var thisYear = new Date().getFullYear();

    for (var i = 2019; i <= thisYear + 1; i++) {
      years.push({
        value: i,
        label: i
      });
    }

    var defaultValue = years.find(function (item) {
      return item.value == (_this.props.value ? _this.props.value : 2019);
    });
    return React.createElement("div", null, React.createElement(Select, {
      options: years,
      defaultValue: defaultValue,
      onChange: function onChange(value) {
        return _this.props.onChange(value.value);
      }
    }));
  };

  return SelectYear;
}(React.Component);

export default SelectYear;