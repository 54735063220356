var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import EthIndicator from './EthIndicator';
import FieldRegister from 'digimaker-ui/FieldRegister';
import EthDocumentList from './fieldtype/EthDocumentList';
import EthTableList from './fieldtype/EthTableList';
import OutputH from './fieldtype/OutputH';
import Preview from './fieldtype/Preview';
import Custom from './fieldtype/Custom'; // @ts-ignore

import LoadFields from 'digimaker-ui/LoadFields';

var ViewReport =
/** @class */
function (_super) {
  __extends(ViewReport, _super);

  function ViewReport(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {};
    return _this;
  }

  ViewReport.prototype.componentDidMount = function () {
    FieldRegister.registerComponent('preview', Preview);
    FieldRegister.registerComponent('output_h', OutputH);
    FieldRegister.registerComponent('eth_documentlist', EthDocumentList);
    FieldRegister.registerComponent('eth_tablelist', EthTableList);
    FieldRegister.registerComponent('report/step3_a1', Custom);
    FieldRegister.registerComponent('report/indicator', Custom);
    FieldRegister.registerComponent('user/preferance', Custom);
  };

  ViewReport.prototype.afterField = function (fieldDef, data, validation) {
    if (fieldDef.parameters && fieldDef.parameters.has_indicator) {
      return React.createElement(EthIndicator, {
        fromField: fieldDef.identifier,
        parameters: fieldDef.parameters,
        data: data['indicator'],
        mode: 'view',
        validation: validation && validation.fields['indicator'] ? validation.fields['indicator'] : ''
      }); //{this.state.validation?this.state.validation.fields['indicator']:''}
    }
  };

  ViewReport.prototype.render = function () {
    var data = this.props.content;
    return React.createElement("div", null, React.createElement("div", {
      className: "preview"
    }, React.createElement(LoadFields, {
      type: this.props.content.content_type,
      language: this.props.language,
      validation: '',
      mode: 'view',
      data: data,
      afterField: this.afterField
    })));
  };

  return ViewReport;
}(React.Component);

export default ViewReport;