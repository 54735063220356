import React, { useState } from "react";
import { BtnBold, BtnItalic, BtnUnderline, Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
export var RichEditor = function RichEditor(props) {
  var _a = useState(props.data),
      data = _a[0],
      setData = _a[1];

  var breakToBr = function breakToBr(str) {
    if (!str) {
      return str;
    } else {
      return str.replace(/\n/g, "<br />");
    }
  };

  var onPaste = function onPaste(e) {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    var arr = text.split("\n");
    var html = "<div>";

    if (arr.length > 1) {
      for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        var item = arr_1[_i];
        html += item + "</div><div>";
      }
    } else {
      html += text;
    }

    html += "</div>";
    document.execCommand("insertHTML", false, html);
  };

  return React.createElement(React.Fragment, null, props.mode == 'edit' && React.createElement(EditorProvider, null, React.createElement(Editor, {
    containerProps: {
      style: {
        resize: "both"
      }
    },
    value: breakToBr(data),
    onPaste: onPaste,
    onChange: function onChange(e) {
      setData(e.target.value);

      if (props.onChange) {
        props.onChange(e);
      }
    }
  }, React.createElement(Toolbar, null, React.createElement(BtnBold, null), React.createElement(BtnItalic, null), React.createElement(BtnUnderline, null))), React.createElement("input", {
    type: "hidden",
    name: props.name,
    value: data
  })), props.mode != 'edit' && React.createElement("div", {
    className: "richtext-readonly",
    dangerouslySetInnerHTML: {
      __html: breakToBr(props.data)
    }
  }));
};