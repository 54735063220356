import { env } from 'process';
import * as React from 'react';

export const BlockPartner = (props:{content:any})=>{
    return <div>
            <div><img className="width-full" src={process.env.REACT_APP_ASSET_URL+'/'+props.content.logo} /></div>
            <div className="font-weight-bold">{props.content.name}</div>
            <div className="dmb-title">{props.content.description}</div>            
        </div>
}
