import * as React from 'react';
import { RouteProps } from 'react-router';
import Moment from 'react-moment';
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import { EthContext } from 'eth-common/EthContext';
import i18n from "eth-common/i18n/i18n";
import {FetchWithAuth} from 'digimaker-ui/util';

// @ts-ignore
import * as utils from 'digimaker-ui/utils';

// @ts-ignore
import LoadFields from 'digimaker-ui/LoadFields';

import './ViewProfile.css';

import fetch from "isomorphic-fetch";
import UploadLogo from './UploadLogo';
import { useEffect } from 'react';
export default class ViewProfile extends React.Component<{}, { id: any, data: any, mode: string, success: boolean, allowedFields:any, validation: any }> {

  constructor(props: any) {
    super(props);
    this.state = { id: '', data: '', mode: 'view', validation: '', allowedFields:[], success: false };
  }

  fetchContent() {
    //fetch content and set to context
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/user/current/report')
      .then((data) => {
        this.setState({ id: data.data.id, data: data.data });
      });

  }

  //when init
  componentDidMount() {
    this.fetchContent();
  }

  switchMode(e: any, mode: string) {
    e.preventDefault();
    if (mode == 'view') {
      this.fetchContent();
    }else{
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/access/update-fields/current-user')
      .then((data:any)=>{
          this.setState({allowedFields:data.data});
      });
    }
    this.setState({ mode: mode });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    let dataObject = utils.buildObject(event.target);
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/update/' + this.state.id, {
      method: 'POST',
      body: JSON.stringify(dataObject),
    }).then((data) => {
      if (data.data === true) {
        this.setState({ success: true });
      } else {
        this.setState({ validation: data.data })
      }
    })
  }

  render() {
    if (!this.state.data) {
      return <div className="loading"></div>
    }

    let content = this.state.data;

    return (<div className="profile">
      <h1>{i18n.t("User profile")} {this.state.data.name}</h1>

      <div className="right">
        <ReportLanguage />
        <UploadLogo />
      </div>

      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className="user-profile" key={this.state.mode}>
          <LoadFields
            type={"user"}
            language={this.context.language}
            mode={this.state.mode}
            data={content}
            beforeField={() => { }}
            afterField={() => { }}
            editFields={this.state.mode=='edit'?this.state.allowedFields:null}
            validation={this.state.validation} />
        </div>
        {this.state.mode == 'view' && <div className="block"><input type="button" className="btn btn-primary btn-sm" onClick={(e: any) => { this.switchMode(e, 'edit'); }} value={i18n.t("Edit").toString()} /></div>}

        {this.state.mode == 'edit' &&
          <div className="block">
            {this.state.success && <div className="block alert alert-info">Profile Updated</div>}
            <input type="submit" className="btn-primary btn-sm" value={i18n.t("Submit").toString()} /> &nbsp;
              <input type="reset" className="btn-secondary btn-sm" value={i18n.t("Reset").toString()} /> &nbsp;
              <a href="#" onClick={(e: any) => { this.switchMode(e, 'view') }}>{i18n.t("Back").toString()}</a>
          </div>
        }
      </form>
    </div>)
  }
}

ViewProfile.contextType = EthContext;


const ReportLanguage = ()=>{
  const [language, setLanguage] = React.useState('');

  useEffect(()=>{
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/user/current')
      .then((data:any)=>{
        setLanguage(data.data.member.report_language.value);
      });
  },[]);
  
  const switchLanguage = ()=>{
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/user/set-report-language?language='+(language!='eng-GB'?'eng-GB':'nor-NO'), 
    {method:'POST'})
      .then((data:any)=>{
        if(data.error===false){
          setLanguage(data.data);
        }else{
          window.alert(data.data.message);
        }
      });
  }

  return <div>
      <div>
        <div>{i18n.t("Language of report pdf")}: {language=='eng-GB'?'English':'Norsk'}     
          &nbsp;&nbsp;(<a href="/" onClick={(e)=>{e.preventDefault(); switchLanguage()}}>{i18n.t("Switch to")} {language=='eng-GB'?'Norsk':'English'}</a>)</div>
      </div>
      <br />
    </div>
}