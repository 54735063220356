var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import * as React from 'react';
import ReactExport from "react-export-excel";
import { FetchWithAuth } from 'digimaker-ui/util';
import SelectYear from './SelectYear';
import { EthContext } from './EthContext';
import i18n from './i18n/i18n';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'; //note: year is only for self.

var EthBenchmark =
/** @class */
function (_super) {
  __extends(EthBenchmark, _super);

  function EthBenchmark(props) {
    var _this = _super.call(this, props) || this;

    var currentID = props.self ? 'self' : '';
    _this.state = {
      currentID: currentID,
      memberlist: '',
      year: _this.props.year || 2019,
      def: '',
      current: '',
      data: '',
      currentCompare: "minimum",
      label: '',
      message: ''
    };
    return _this;
  } //fetch content and set to context


  EthBenchmark.prototype.Init = function () {
    var _this = this;

    if (this.context.currentYear) {
      this.setState({
        year: this.context.currentYear - 1
      });
    }

    if (this.props.self) {
      this.changeCurrent(this.props.year ? this.props.year : this.context.currentYear - 1, 'self');
    } else {
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/memberlist').then(function (data) {
        _this.setState({
          memberlist: data
        });
      });
    }

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/getlevels/' + (this.props.language ? this.props.language : 'nor-NO') + '?calculated=true').then(function (data) {
      _this.setState({
        def: data
      });
    });
  };

  EthBenchmark.prototype.componentDidMount = function () {
    this.Init();
  };

  EthBenchmark.prototype.componentDidUpdate = function (prevProps) {
    if (this.props.language !== prevProps.language) {
      this.Init();
    }
  }; //change current dropdown


  EthBenchmark.prototype.changeCurrent = function (year, value) {
    var _this = this;

    if (value) {
      this.setState({
        message: ''
      });
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/level?id=' + value + '&year=' + year).then(function (data) {
        if (data.error === false) {
          _this.setState({
            currentID: value,
            current: data.data
          });

          _this.compare(_this.state.year, 'minimum');
        } else {
          _this.setState({
            message: data.data.message
          });
        }
      }).catch(function (err) {
        return _this.setState({
          message: "Can not get data."
        });
      });
    }
  };

  EthBenchmark.prototype.changeYear = function (year) {
    this.changeCurrent(year, this.props.self ? 'self' : this.state.currentID);
    this.compare(year, 'minimum');
    this.setState({
      year: year
    });
  }; //after selecting compared criteria


  EthBenchmark.prototype.compare = function (year, value) {
    return __awaiter(this, void 0, void 0, function () {
      var data_1, i, requestYear, data;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.setState({
              message: '',
              label: ''
            });
            if (!(value == 'minimum')) return [3
            /*break*/
            , 1];
            data_1 = {};
            Object.keys(this.state.current).map(function (key) {
              data_1[key] = 1;
            });
            this.setState({
              currentCompare: value,
              data: data_1
            });
            return [3
            /*break*/
            , 7];

          case 1:
            if (!(value == 'lastyear')) return [3
            /*break*/
            , 6];
            this.setState({
              message: '',
              data: [],
              label: []
            });
            i = 1;
            _a.label = 2;

          case 2:
            if (!(i <= 3)) return [3
            /*break*/
            , 5];
            requestYear = year - i;
            if (!(requestYear >= 2019)) return [3
            /*break*/
            , 4];
            return [4
            /*yield*/
            , FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/level?id=' + this.state.currentID + '&year=' + requestYear)];

          case 3:
            data = _a.sent();

            if (data.error === false) {
              this.setState({
                currentCompare: value,
                data: __spreadArrays(this.state.data, [data.data]),
                label: __spreadArrays(this.state.label, [requestYear])
              });
            }

            _a.label = 4;

          case 4:
            i++;
            return [3
            /*break*/
            , 2];

          case 5:
            return [3
            /*break*/
            , 7];

          case 6:
            FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/benchmark?user=' + this.state.currentID + '&category=' + value + '&year=' + year).then(function (data) {
              _this.setState({
                currentCompare: value,
                data: data.data,
                label: data.name
              });
            }).catch(function (err) {
              _this.setState({
                message: err.message
              });
            });
            _a.label = 7;

          case 7:
            return [2
            /*return*/
            ];
        }
      });
    });
  };

  EthBenchmark.prototype.downloadExcel = function (data, currentName, comparedName) {
    var ExcelFile = ReactExport.ExcelFile;
    var ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    var ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return React.createElement(ExcelFile, {
      filename: "benchmark",
      element: React.createElement("a", {
        className: "right noprint",
        href: "#",
        onClick: function onClick(e) {
          e.preventDefault();
        }
      }, React.createElement("i", {
        className: "icon icon-export excel"
      }), this.props.language == "eng-GB" ? "Download Excel" : "Last ned excel")
    }, React.createElement(ExcelSheet, {
      data: data,
      name: "Sheet"
    }, React.createElement(ExcelColumn, {
      label: "",
      value: "fullname"
    }), React.createElement(ExcelColumn, {
      label: currentName,
      value: "current"
    }), React.createElement(ExcelColumn, {
      label: comparedName,
      value: "compared"
    })));
  };

  EthBenchmark.prototype.render = function () {
    var _this = this;

    if (!this.state.def || !this.state.memberlist && !this.props.self) {
      return React.createElement("div", {
        className: "loading"
      });
    }

    var data = [];
    var def = this.state.def;
    def.map(function (item) {
      Object.keys(_this.state.current).map(function (key) {
        var name = '';

        if (item.identifier == key) {
          name = item.name.substring(0, 3);
          data.push({
            'name': name,
            'fullname': item.name,
            'key': key,
            'current': _this.state.current[key]
          });
        }
      });
    });
    var currentName = this.props.self ? this.props.language == 'eng-GB' ? 'Mine' : 'Min' : '';

    for (var _i = 0, _a = this.state.memberlist; _i < _a.length; _i++) {
      var item = _a[_i];

      if (item.id == this.state.currentID) {
        currentName = item.name;
      }
    }

    data.map(function (item, index) {
      if (Array.isArray(_this.state.data)) {
        _this.state.data.map(function (oneData, j) {
          item['item_' + j] = Number(oneData[item['key']]).toFixed(2);
          data[index] = item;
        });
      } else {
        item['compared'] = Number(_this.state.data[item['key']]).toFixed(2);
        data[index] = item;
      }
    });
    var options = {
      'minimum': 'Basisnivå',
      'lastyear': 'Tidligere år',
      'branch': 'Snitt i samme bransje',
      'category': 'Snitt i samme størrelseskategori',
      'year': 'Snitt i samme innmeldingsår'
    };

    if (this.props.language == 'eng-GB') {
      options = {
        'minimum': 'Minimum level',
        'lastyear': 'Last year',
        'branch': 'Average in same branch',
        'category': 'Average in same size category',
        'year': 'Average in same registration year.'
      };
    }

    var dashboard = this.props.dashboard == true;
    return React.createElement("div", null, !dashboard && React.createElement("h2", null, "Benchmark"), !dashboard && React.createElement("span", null, this.downloadExcel(data, currentName, options[this.state.currentCompare])), !dashboard && React.createElement("a", {
      className: "noprint",
      href: "javascript:window.print();"
    }, React.createElement("i", {
      className: "fas fa-print"
    }), " ", i18n.t('Print')), React.createElement("div", {
      className: "container"
    }, !dashboard && React.createElement(SelectYear, {
      onChange: function onChange(year) {
        return _this.changeYear(year);
      },
      value: this.context.currentYear - 1
    }), " ", React.createElement("br", null), React.createElement("div", {
      className: "row"
    }, !this.props.self && React.createElement("div", {
      className: "col-sm"
    }, this.state.memberlist && React.createElement("select", {
      className: "form-control",
      onChange: function onChange(e) {
        _this.changeCurrent(_this.state.year, e.target.value);
      }
    }, React.createElement("option", {
      value: "0"
    }, "Medlem"), this.state.memberlist.map(function (item) {
      return React.createElement("option", {
        value: item.id
      }, item.name);
    }))), React.createElement("div", {
      className: "col-sm"
    }, !dashboard && React.createElement("select", {
      className: "form-control",
      style: {
        width: '50%',
        display: 'inline-block'
      },
      onChange: function onChange(e) {
        _this.compare(_this.state.year, e.target.value);
      }
    }, Object.keys(options).map(function (key) {
      return React.createElement("option", {
        value: key,
        selected: key == _this.state.currentCompare
      }, options[key]);
    })), this.state.label && React.createElement("span", null, "\xA0", typeof this.state.label == 'string' ? this.state.label : ''))), React.createElement("div", {
      className: "row"
    }, React.createElement("div", {
      className: "col-sm"
    }, React.createElement("div", {
      className: "block"
    }, this.state.message && React.createElement("div", {
      className: "alert alert-success"
    }, this.state.message), !this.state.message && data.length > 0 && React.createElement(BarChart, {
      width: dashboard ? 500 : 800,
      height: dashboard ? 250 : 500,
      data: data,
      barGap: dashboard ? 5 : 8,
      margin: {
        top: 20,
        bottom: 0
      }
    }, React.createElement(CartesianGrid, {
      strokeDasharray: "3 3"
    }), React.createElement(XAxis, {
      dataKey: "name"
    }), React.createElement(YAxis, {
      domain: [0, 4]
    }), React.createElement(Tooltip, {
      itemStyle: {
        color: '#000000',
        fontSize: '85%'
      }
    }), React.createElement(Legend, null), React.createElement(Bar, {
      name: currentName,
      dataKey: "current",
      fill: "#377e22"
    }), !Array.isArray(this.state.data) && React.createElement(Bar, {
      name: options[this.state.currentCompare],
      dataKey: "compared",
      fill: "#d3d591"
    }), Array.isArray(this.state.data) && this.state.data.map(function (oneData, index) {
      return React.createElement(Bar, {
        name: _this.state.label[index],
        dataKey: 'item_' + index,
        fill: ["#fbd129", "#fe9393", "#fe93ea"][index]
      });
    })), !dashboard && React.createElement("div", {
      className: "block",
      style: {
        fontSize: '85%'
      }
    }, def.map(function (item) {
      return React.createElement("div", null, item.name);
    })), React.createElement("div", {
      className: "block"
    }, this.props.language == "eng-GB" && React.createElement("div", null, "Only the levels 'basis' and 'advanced' are quality assured by the Ethical Commerce Norway Secretariat. Levels above this are based on members' self-assessment."), this.props.language != "eng-GB" && React.createElement("div", null, "Kun niv\xE5ene 'basis' og 'gjennomf\xF8rer' er kvalitetssikret av Etisk handel Norges sekretariat. Niv\xE5er over dette er basert p\xE5 medlemmers egenvurdering.")))))));
  };

  return EthBenchmark;
}(React.Component);

export default EthBenchmark;
EthBenchmark.contextType = EthContext;