var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import React from 'react'; // @ts-ignore

import FileUpload from 'digimaker-ui/FileUpload';
import ReactTooltip from 'react-tooltip';

var Image =
/** @class */
function (_super) {
  __extends(Image, _super);

  function Image(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {
      data: props.data
    };
    return _this;
  }

  Image.prototype.updated = function (data) {
    this.setState({
      data: data.nameUploaded
    });
  };

  Image.prototype.inline = function () {
    if (this.state.data) {
      var thumbnailPath = '';

      if (!this.state.data.startsWith('upload_temp/')) {
        var pathConfig = process.env.REACT_APP_THUMB_PATH ? process.env.REACT_APP_THUMB_PATH : '';
        thumbnailPath = pathConfig.replace("{imagepath}", this.state.data);
      } else {
        thumbnailPath = this.state.data;
      }

      thumbnailPath = process.env.REACT_APP_ASSET_URL + "/" + thumbnailPath;
      return React.createElement("div", null, React.createElement("img", {
        className: "fieldtype-image-img",
        src: thumbnailPath
      }));
    } else {
      return '';
    }
  };

  Image.prototype.view = function () {
    return React.createElement(React.Fragment, null, React.createElement("label", {
      className: "field-label"
    }, this.props.definition.name, ": "), React.createElement("div", {
      className: "field-value"
    }, this.inline()));
  };

  Image.prototype.edit = function () {
    var _this = this;

    return React.createElement(React.Fragment, null, React.createElement("label", {
      className: "field-label",
      htmlFor: this.props.definition.identifier
    }, this.props.definition.name, this.props.definition.description && React.createElement("i", {
      className: "icon-info",
      "data-for": this.props.definition.identifier + '-desciption',
      "data-tip": ""
    }), this.props.definition.description && React.createElement(ReactTooltip, {
      id: this.props.definition.identifier + '-desciption',
      effect: "solid",
      place: "right",
      html: true,
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, this.props.definition.description), ":"), React.createElement("div", {
      className: "field-value"
    }, React.createElement(FileUpload, {
      name: this.props.definition.identifier,
      service: "content",
      format: "image/*",
      value: this.state.data,
      onSuccess: function onSuccess(data) {
        _this.updated(data);
      }
    }), this.state.data && React.createElement(React.Fragment, null, React.createElement("a", {
      href: "/",
      title: "Remove",
      onClick: function onClick(e) {
        e.preventDefault();

        _this.setState({
          data: ''
        });
      }
    }, React.createElement("i", {
      className: "far fa-trash-alt"
    })), this.state.data == this.props.data && this.inline(), this.state.data != this.props.data && React.createElement("img", {
      src: process.env.REACT_APP_ASSET_URL + "/" + this.state.data
    }))));
  };

  Image.prototype.render = function () {
    if (this.props.mode == 'view') {
      return this.view();
    } else if (this.props.mode == 'inline') {
      return this.inline();
    } else {
      return this.edit();
    }
  };

  return Image;
}(React.Component);

export default Image;