var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";

var Percentage =
/** @class */
function (_super) {
  __extends(Percentage, _super);

  function Percentage(props) {
    var _this = _super.call(this, props) || this;

    _this.onChange = function (e) {
      if (Number(e.target.value)) {
        var regex = /^100(\.[0]{1,2})?|([0-9]|[1-9][0-9])(\.[0-9]{1,2})?$/g;
        var value = e.target.value.match(regex);

        _this.setState({
          value: value
        });
      } else if (e.target.value == '') {
        _this.setState({
          value: ''
        });
      }
    };

    _this.componentDidMount = function () {
      _this.setState({
        value: _this.props.value
      });
    };

    _this.state = {
      value: ''
    };
    return _this;
  }

  Percentage.prototype.render = function () {
    return React.createElement("div", {
      className: "block"
    }, React.createElement("input", {
      type: "text",
      className: "form-control",
      disabled: this.props.disabled,
      defaultValue: this.props.value,
      name: this.props.name,
      value: this.state.value,
      onChange: this.onChange
    }));
  };

  return Percentage;
}(React.Component);

export { Percentage };