import * as React from 'react';
import {FetchWithAuth} from 'digimaker-ui/util';
import { RenderContent } from '../RenderContent';

export default class Blocks extends React.Component<{content:any}, {blocktree:any}> {
    constructor(props: any) {
        super(props);
        this.state = {blocktree:''};
      }

      componentDidMount(){
        this.fetchBlocks();
      }
    
    async fetchBlocks(){
        let id = this.props.content.id;
        let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/treemenu/'+id+'?type=block');
        if( data.error === false ){
            this.setState({blocktree: data.data});
        }
    }    

    renderTree(node:any){
        return <div className={node.content.theme?("theme-"+node.content.theme):(node.content.content_type+'-mode-line')}>
            <RenderContent mode='line' content={node.content} />

            {node.children&&node.children.map((child)=>{
                return this.renderTree(child)
            })}
        </div>
    }
    
    render(){
            return <div>
                {this.state.blocktree&&this.state.blocktree.children.map((child)=>
                     this.renderTree(child)
                )}
                </div>
    }
}