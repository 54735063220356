var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { EthContext } from './EthContext';
import Select from 'react-select';
import i18n from './i18n/i18n';
import { FetchWithAuth } from 'digimaker-ui/util';
var addedIndicators = [];
var currentYear = '';
var groups = '';
var indicatorType = '';
var descriptions = '';

var EthIndicator =
/** @class */
function (_super) {
  __extends(EthIndicator, _super);

  function EthIndicator(props) {
    var _this = _super.call(this, props) || this;

    var data = _this.props.data;

    if (data && typeof data === 'string') {
      data = JSON.parse(data);
    }

    var added = [];

    if (data) {
      data.map(function (dataItem) {
        var id = parseInt(dataItem.type).toString();

        if (!addedIndicators.includes(id)) {
          addedIndicators.push(id);
        }

        if (dataItem.fromfield == _this.props.fromField) {
          var id_1 = parseInt(dataItem.type);

          if (id_1 < 0) {
            added.push(id_1 + "_" + dataItem.count);
          } else {
            added.push(id_1.toString());
          }
        }
      });
    }

    _this.state = {
      currentYear: '',
      groups: '',
      indicatorType: '',
      description: '',
      added: added
    };
    return _this;
  }

  EthIndicator.prototype.componentDidMount = function () {
    this.fetchList();
  };

  EthIndicator.prototype.fetchList = function () {
    var _this = this; //todo: use promise


    if (groups) {
      this.setState({
        groups: groups,
        indicatorType: indicatorType,
        description: this.getDescription(descriptions)
      });
    } else {
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/setting/get').then(function (data) {
        var list = data.indicatortype;
        groups = data.indicatorgroup;
        indicatorType = list;
        descriptions = data.indicatorDescription;

        _this.setState({
          groups: groups,
          indicatorType: indicatorType,
          description: _this.getDescription(descriptions)
        });
      }).catch(function (err) {//todo: error handling
      });
    }

    if (currentYear) {
      this.setState({
        currentYear: currentYear
      });
    } else {
      FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/current-year').then(function (data) {
        currentYear = parseInt(data.data);

        _this.setState({
          currentYear: currentYear
        });
      });
    }
  };

  EthIndicator.prototype.getDescription = function (descriptions) {
    var _this = this;

    var currentDescription = descriptions.find(function (item) {
      return item.id == _this.props.fromField.split("-")[0];
    });
    var description = '';

    if (currentDescription) {
      var language = this.context.language;

      if (language == 'eng-GB') {
        description = currentDescription['_eng'];
      } else {
        description = currentDescription['name'];
      }
    }

    return description;
  };

  EthIndicator.prototype.addIndicator = function (value) {
    var id = parseInt(value).toString();
    var added = this.state.added;

    if (parseInt(value) > 0) {
      addedIndicators.push(id);
      added.push(id);
    } else {
      var count = Date.now();
      added.push(id + "_" + count);
    }

    this.setState({
      added: added
    });
  };

  EthIndicator.prototype.getDataByID = function (id, count) {
    var data = this.props.data;

    if (data && typeof data === 'string') {
      data = JSON.parse(data);
    }

    var result = '';

    if (data) {
      data.map(function (value) {
        if (parseInt(value.type) == id) {
          if (typeof count != undefined) {
            if (value.count == count) {
              result = value;
              return;
            }
          } else {
            result = value;
            return;
          }
        }
      });
    }

    return result;
  };

  EthIndicator.prototype.getIndicatorType = function (id) {
    var result;
    result = this.state.indicatorType.filter(function (item) {
      if (item.id == id) {
        return item;
      }
    });
    return result[0];
  }; //delete


  EthIndicator.prototype.delete = function (i, id) {
    addedIndicators.map(function (item, j) {
      if (item == id) {
        addedIndicators.splice(j, 1);
        return;
      }
    });
    var added = this.state.added;
    added.splice(i, 1);
    this.setState({
      added: added
    });
  }; //force to rerender list


  EthIndicator.prototype.updateList = function () {
    this.setState({
      indicatorType: this.state.indicatorType
    });
  };

  EthIndicator.prototype.render = function () {
    var _this = this;

    if (!this.state.groups || !this.state.indicatorType || !this.state.currentYear) {
      return React.createElement("div", {
        className: "loading"
      });
    }

    var isEnglish = this.context.language == 'eng-GB';
    var isViewMode = this.props.mode == 'view';
    var groups = this.state.groups;
    var validGroups = [];

    if (this.props.parameters.indicator_group) {
      for (var _i = 0, _a = this.props.parameters.indicator_group; _i < _a.length; _i++) {
        var groupID = _a[_i];
        validGroups.push(groupID);
      }
    }

    validGroups.push(0);
    var currentYear = this.state.currentYear;
    return React.createElement("div", {
      className: "indicator block"
    }, !isViewMode && React.createElement("input", {
      type: "hidden",
      name: "indicator_from[]",
      value: this.props.fromField
    }), this.state.added.map(function (identifier, i) {
      var arr = identifier.split('_');
      var id = arr[0];
      var count = arr.length > 0 ? parseInt(arr[1]) : 0;

      var indicator = _this.getIndicatorType(id);

      var validation = "";

      if (_this.props.validation) {
        validation = JSON.parse(_this.props.validation);
      }

      var namePrefix = '';
      var data = '';
      namePrefix = "indicator[" + _this.props.fromField + "][" + i + "]";

      if (parseInt(id) < 0) {
        //self defined
        data = _this.getDataByID(parseInt(id), count);
      } else {
        data = _this.getDataByID(parseInt(id));
      }

      var years = [currentYear, currentYear - 1, currentYear - 2];

      if (!isViewMode) {
        if (id == "-2") {
          years = [currentYear, currentYear - 1];
        } else if (id == "-4") {
          years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
        }
      } else {
        years = Object.keys(data['years']).sort().reverse();
      }

      var text = "";

      if (isEnglish) {
        text = indicator['_eng'];
      } else {
        text = indicator.text;
      }

      return React.createElement("div", {
        className: "indicator-body"
      }, !isViewMode && React.createElement("input", {
        type: "button",
        className: "btn btn-sm right-float",
        "data-i": i,
        value: "X",
        onClick: function onClick() {
          _this.delete(i, indicator.id);
        }
      }), React.createElement("div", null, text), parseInt(id) < 0 && React.createElement("div", null, React.createElement("label", null, "Tittel: "), React.createElement("input", {
        disabled: isViewMode,
        name: namePrefix + "[title]",
        defaultValue: data && data['title'] ? data['title'] : '',
        className: "form-control",
        type: "text"
      })), React.createElement("input", {
        type: "hidden",
        disabled: isViewMode,
        name: namePrefix + "[fromfield]",
        value: _this.props.fromField
      }), indicator.group == 0 && React.createElement("div", null, i18n.t('Type'), ":", React.createElement(Select, {
        isDisabled: isViewMode,
        name: namePrefix + "[valuetype]",
        defaultValue: data && data['valuetype'] == '2' ? {
          label: i18n.t('Percentage'),
          value: '2'
        } : {
          label: i18n.t('Number'),
          value: '1'
        },
        options: [{
          label: i18n.t('Number'),
          value: '1'
        }, {
          label: i18n.t('Percentage'),
          value: '2'
        }]
      }), !isViewMode && React.createElement("input", {
        type: "hidden",
        name: namePrefix + "[count]",
        value: count
      })), React.createElement("table", null, React.createElement("tr", null, React.createElement("td", null, i18n.t('Year')), years.map(function (year) {
        return React.createElement("td", null, year);
      })), React.createElement("tr", null, React.createElement("td", null, parseInt(id) < 0 ? i18n.t('Value') : i18n.t('Percentage')), React.createElement("input", {
        name: namePrefix + "[type]",
        disabled: isViewMode,
        type: "hidden",
        value: indicator.id
      }), years.map(function (year) {
        return React.createElement("td", null, React.createElement("input", {
          name: namePrefix + "[years][" + year + "]",
          disabled: isViewMode,
          className: "form-control",
          type: indicator.type ? indicator.type : "number",
          defaultValue: data ? data['years'][year] : ''
        }), validation && validation[i] && validation[i][year] && React.createElement("div", {
          className: "error"
        }, validation[i][year]));
      }))), React.createElement("div", null, React.createElement("label", null, "Kommentar:"), React.createElement("textarea", {
        name: namePrefix + "[comment]",
        disabled: isViewMode,
        className: "form-control",
        defaultValue: data ? data['comment'] : ''
      })), validation && validation[i] && validation[i]["common"] && React.createElement("div", {
        className: "error"
      }, validation[i]["common"]));
    }), !isViewMode && React.createElement("div", {
      className: "indicator-add"
    }, React.createElement("label", null, i18n.t("Add indicator"), ":"), this.state.description && React.createElement("i", {
      className: "icon-info",
      "data-for": this.props.fromField + '-indicator-description',
      "data-tip": ""
    }), this.state.description && React.createElement(ReactTooltip, {
      id: this.props.fromField + '-indicator-description',
      html: true,
      effect: "solid",
      place: "right",
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, this.state.description), React.createElement("select", {
      className: "form-control",
      value: "-1",
      onFocus: function onFocus(e) {
        return _this.updateList();
      },
      onChange: function onChange(e) {
        _this.addIndicator(e.target.value);
      }
    }, React.createElement("option", {
      value: "-1"
    }, i18n.t("Select indicator from list(optional)")), validGroups.map(function (groupID) {
      var listInGroup = _this.state.indicatorType.filter(function (item) {
        return item.group == groupID && (!addedIndicators.includes(item.id) || parseInt(item.id) < 0);
      });

      var options = listInGroup.map(function (item) {
        return React.createElement("option", {
          value: item.id
        }, isEnglish ? item['_eng'] : item.text);
      });

      var group = _this.state.groups.find(function (item) {
        return item.id == groupID;
      });

      return options.length > 0 && React.createElement("optgroup", {
        label: isEnglish ? group['_eng'] : group.text
      }, options);
    }))));
  };

  return EthIndicator;
}(React.Component);

export default EthIndicator;
EthIndicator.contextType = EthContext;