import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Moment from 'react-moment';
import {FetchWithAuth} from 'digimaker-ui/util';
import { RenderContent } from '../RenderContent';
import { ExpandableLine } from '../shared/Common';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import './Resource.css';

export default class Resource extends React.Component<{content:any}, {data:any, keyword:string, loading:boolean, options:any, selected:any}> {
    constructor(props: any) {
        super(props);
        this.state = {data:'', keyword:'', loading: false, options:'', selected:{}};
      }

    componentDidMount(){
        this.fetchOptions();
    }

    async fetchOptions(){
        let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/contenttype/get/resource');
        this.setState({loading: false});
        if( data.error === false ){            
            // this.setState({data:data.data.list});
            let def = data.data;
            let countryList = this.convert(def, 'country');
            let codeList = this.convert(def,'conduct_code');
            let industryList = this.convert(def,'industry');
            let stepList = this.convert(def,'step');
            this.setState({options: {country: countryList, code: codeList, industry: industryList, step: stepList}});
        }
    }

    convert(def, identifier){
        let fields = def.fields;
        let result:Array<any> = [];
        for( let field of fields ){
            if( field.identifier == identifier ){
                for( let item of field.parameters.list ){
                    result.push({label: item.text, value: item.value});
                }
            }
        }
        return result;
    }

    search = async ()=>{
        let filter:Array<any> = [];
        if( this.state.keyword ){
            filter.push('field.title=contain:'+this.state.keyword);
        }
        
        for(let key of Object.keys( this.state.selected)){
            filter.push('field.'+key+'='+this.state.selected[key]);
        }

        this.setState({loading: true});
        let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/resource?sortby=title desc&limit=0&'+filter.join('&'));        
        if( data.error === false ){            
            let ids:Array<number> = [];
            let list:any = data.data.list;
            for( let item of list ){
                if( item.file ){
                    ids.push(item.file);
                }
            }

            let fileList = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/list/file?cid='+ids.join(',')+'&limit=0');        
            let result:any = [];
            for( let item of list ){
                for( let fileItem of fileList.data.list ){
                    if( item.file == fileItem.id ){
                        item['path'] = fileItem['path']?(process.env.REACT_APP_ASSET_URL+'/'+fileItem['path']):fileItem['link'];
                    }
                }
                result.push(item);
            }
            this.setState({data:result});
        }
        this.setState({loading: false});
    }

    select = (e, identifier)=>{
        let values = this.state.selected;
        if( e ){
            values[identifier] = e['value'];
        }else{
            delete values[identifier];
        }
        this.setState({selected: values})
    }

    render(){
        return <div>
            <h2>Søk i ressursdatabasen</h2>
            <form onSubmit={(e)=>{e.preventDefault()}}>
            <Row>
                <Col md={3}>
                    <label>Hva leter du etter?</label>
                    <input className="form-control" onChange={(e)=>this.setState({keyword: e.target.value})} />
                </Col>
                <Col md={3}>
                    <label>Land/Verdensdel</label>
                    <Select isClearable={true} onChange={e=>this.select(e, 'country')} options={this.state.options.country}>
                    </Select>
                </Col>
                <Col md={2}>
                <label>Trinn</label>
                     <Select isClearable={true} onChange={e=>this.select(e, 'step')} options={this.state.options.step}>
                    </Select>
                </Col>
                <Col md={2}>
                <label>Code of conduct</label>
                     <Select isClearable={true} onChange={e=>this.select(e, 'conduct_code')} options={this.state.options.code}>
                    </Select>
                </Col>
                <Col md={2}>
                <label>Industri</label>
                <Select isClearable={true} onChange={e=>this.select(e, 'industry')} options={this.state.options.industry}>
                    </Select>
                </Col>
            </Row>        
            <Row>                
                <Col>
                    <br />
                    <button type="submit" onClick={this.search} className="btn btn-primary">Search</button>
                    {this.state.loading&&<span className="loading"></span>}
                    <br /><br />
                </Col>
            </Row>
            </form>

            {Array.isArray(this.state.data)&&this.state.data.length==0&&<div>No resource found</div>}
            {Array.isArray(this.state.data)&&this.state.data.length>0&&<div>{this.state.data.length} resource found</div>}

            {this.state.data&&<div className="block">
                {this.state.data.map((item)=>{
                let link = '';
                if( item.link ){
                    link = item.link;
                }else if( item.path ){
                    link = item.path;
                }
                return <div className="resource-line">
                    <a href={link?link:'#'} target={link?"_blank":""}>
                    <div>
                        {(item.resource_from||item.year)&&<div className="font-weight-bold resource-from">{item.resource_from} | {item.year}</div>}
                        <div className="font-weight-bold resource-title">{item.title}</div>                    
                    </div>
                    <div className="line-body block">
                    <Row>
                        <Col md={11}>
                        {item.description}
                        </Col>
                        <Col md={1} className="text-right">
                            {(item.path?true:false)&&<a className="resource-icon" href={link} target="_blank"><i className="fas fa-download"></i></a>}
                            {(item.link?true:false)&&<a className="resource-icon" href={link} target="_blank"><i className="fas fa-external-link-alt"></i></a>}                            
                        </Col>
                    </Row>
                    
                    </div>
                    </a>

                    <hr />
                </div>
                })}
            </div>}
        </div>
    }
}