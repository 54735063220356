import i18n from "i18next";
import translationEng from "./locales/eng-GB/translation.json";
import translationNo from "./locales/nor-NO/translation.json";
i18n.init({
  // we init with resources
  resources: {
    'nor-NO': {
      translations: translationNo
    },
    'eng-GB': {
      translations: translationEng
    }
  },
  fallbackLng: "nor-NO",
  debug: true,
  keySeparator: false,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});
export default i18n;