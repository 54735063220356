var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import * as React from "react";
import { Percentage } from "../Percentage";
import Select from 'react-select';
import i18n from '../i18n/i18n';
import { EthContext } from '../EthContext';
import ReactTooltip from 'react-tooltip';
import { FetchWithAuth } from 'digimaker-ui/util';
import './eth_fieldtype.css';
import { RichEditor } from "../RichEditor";

var EthTableList =
/** @class */
function (_super) {
  __extends(EthTableList, _super);

  function EthTableList(props) {
    var _this = _super.call(this, props) || this;

    _this.removeRow = function (e, id) {
      e.preventDefault();

      var stateData = __spreadArrays(_this.state.list);

      delete stateData[id];

      _this.setState({
        list: stateData
      });
    };

    _this.addRow = function () {
      var def = _this.props.definition;
      var row = {};
      def.parameters.columns.map(function (column) {
        row[column.name] = "";
      });

      if (!_this.state.list) {
        _this.setState({
          list: [row]
        });
      } else {
        _this.setState({
          list: __spreadArrays(_this.state.list, [row])
        });
      }
    };

    _this.state = {
      countries: '',
      topics: '',
      list: [],
      tablelisttext: '',
      selectedValues: {},
      comment: '',
      value: 0
    };
    return _this;
  }

  EthTableList.prototype.fetchList = function () {
    var _this = this;

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/setting/get').then(function (data) {
      _this.setState({
        countries: data.countrylist,
        topics: data.topiclist,
        tablelisttext: data.tablelisttext
      });
    }).catch(function (err) {//todo: error handling
    });
  };

  EthTableList.prototype.componentDidMount = function () {
    var _this = this;

    this.fetchList();

    if (this.props.data) {
      var data = this.props.data;

      if (typeof data === 'string') {
        data = JSON.parse(data);
      }

      this.setState({
        list: data.list,
        comment: data.comment
      });
    } else {
      if (!this.props.definition.parameters.rows) {
        this.addRow();
      }
    }

    if (this.props.definition.parameters.rows) {
      var list_1 = [];
      this.props.definition.parameters.rows.map(function (row, i) {
        var value = {};

        _this.props.definition.parameters.columns.map(function (column) {
          var name = column.name;
          value[name] = row[name] ? row[name] : '';

          if (_this.props.data) {
            var data = _this.props.data;

            if (typeof data === 'string') {
              data = JSON.parse(data);
            }

            if (data.list) {
              var dataRow = data.list[i];

              if (dataRow[name]) {
                value[name] = dataRow[name];
              }
            }
          }
        });

        list_1.push(value);
      });
      this.setState({
        list: list_1
      });
    }
  };

  EthTableList.prototype.getTranslation = function (str) {
    var text = str;

    if (this.context.language == 'eng-GB') {
      var list = this.state.tablelisttext.filter(function (item) {
        return text == item['text'];
      });

      if (list.length > 0 && list[0]['_eng'] != '') {
        text = list[0]['_eng'];
      }

      return text;
    } else {
      return text;
    }
  };

  EthTableList.prototype.render = function () {
    var _this = this;

    var isViewMode = this.props.mode == 'view';
    var def = this.props.definition;
    var _a = this.state,
        countries = _a.countries,
        topics = _a.topics;
    var language = this.context.language;
    var topicList = topics.length > 0 && topics.map(function (item, i) {
      if (language == 'eng-GB') {
        return {
          value: item.id.toString(),
          label: item['_eng']
        };
      }

      return {
        value: item.id.toString(),
        label: item.name
      };
    }, this);
    var countryList = countries.length > 0 && countries.map(function (item, i) {
      if (language == 'eng-GB') {
        return {
          value: item.code,
          label: item['_eng']
        };
      }

      return {
        value: item.code,
        label: item.name
      };
    }, this);

    if (!countries || !topics) {
      return '';
    }

    return React.createElement("div", {
      className: "field " + def.type + " " + def.identifier + " " + (this.props.definition.required ? "required" : "") + (this.props.validation == "1" ? " result-required" : "")
    }, React.createElement("label", null, def.name, def.description && React.createElement("i", {
      className: "icon-info",
      "data-for": def.identifier + '-description',
      "data-tip": ""
    }), def.description && React.createElement(ReactTooltip, {
      id: def.identifier + '-description',
      html: true,
      effect: "solid",
      place: "right",
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, def.description), ":"), React.createElement("div", {
      className: 'field-body'
    }, (!this.state.list || this.state.list.length == 0 || this.state.list.length == 1 && this.state.list[0] == undefined) && React.createElement("input", {
      name: this.props.definition.identifier + "[list][]",
      type: "hidden",
      value: ""
    }), this.state.list && this.state.list.length > 0 && React.createElement("table", null, React.createElement("thead", null, React.createElement("tr", null, def.parameters.columns.map(function (column) {
      return React.createElement("th", null, _this.getTranslation(column.title));
    }))), React.createElement("tbody", null, this.state.list && this.state.list.map(function (row, i) {
      if (row) {
        var keys = Object.keys(row);
        return React.createElement("tr", null, def.parameters.columns.map(function (columnDef) {
          var key = columnDef.name;
          var name = _this.props.definition.identifier + "[list][" + i + "][" + columnDef.name + "]";

          if (columnDef.type == "countrylist") {
            var selected_1 = row[key];

            if (typeof selected_1 == 'string') {
              selected_1 = [selected_1];
            }

            var selectedList = countryList.filter(function (item) {
              return selected_1.includes(item.value);
            });
            return React.createElement("td", {
              className: "countrylist"
            }, React.createElement(Select, {
              defaultValue: selectedList,
              isMulti: columnDef.multiple ? true : false,
              name: name,
              isDisabled: isViewMode,
              placeholder: i18n.t("Select"),
              options: countryList
            }));
          } else if (columnDef.type == "topiclist") {
            var selected_2 = row[key];

            if (typeof selected_2 == 'string') {
              selected_2 = [selected_2];
            }

            var selectedList = topicList.filter(function (item) {
              return selected_2.includes(item.value);
            });
            console.log(selectedList);
            return React.createElement("td", {
              className: "topiclist"
            }, React.createElement(Select, {
              defaultValue: selectedList,
              name: name,
              isDisabled: isViewMode,
              isMulti: true,
              options: topicList
            }));
          } else if (columnDef.type == "percentage") {
            return React.createElement("td", null, React.createElement(Percentage, {
              disabled: isViewMode,
              name: name,
              value: row[key]
            }));
          } else if (columnDef.type == "output") {
            var text = row[key];
            return React.createElement("td", null, _this.getTranslation(text));
          } else if (columnDef.type == "multitext") {
            return React.createElement("td", null, React.createElement(RichEditor, {
              name: name,
              mode: isViewMode ? 'view' : 'edit',
              data: row[key]
            }));
          } else {
            return React.createElement("td", null, React.createElement("input", {
              name: name,
              type: columnDef.type,
              className: 'form-control',
              disabled: isViewMode,
              defaultValue: row[key]
            }));
          }
        }), " ", React.createElement("td", null, !isViewMode && !def.parameters.rows && React.createElement("a", {
          href: "#",
          onClick: function onClick(e) {
            return _this.removeRow(e, i);
          }
        }, React.createElement("i", {
          className: "fas fa-trash"
        }))));
      }
    }))), !isViewMode && def.parameters && !def.parameters.rows && React.createElement("div", {
      className: 'block'
    }, React.createElement("button", {
      type: 'button',
      onClick: this.addRow,
      className: 'btn btn-primary btn-sm'
    }, React.createElement("i", {
      className: "fas fa-plus"
    }), " ", i18n.t("Add").toString())), def.parameters && !def.parameters.nocomment && React.createElement("div", {
      className: "block"
    }, React.createElement("label", null, i18n.t("Comment")), React.createElement(RichEditor, {
      name: this.props.definition.identifier + "[comment]",
      mode: isViewMode ? 'view' : 'edit',
      data: this.state.comment,
      onChange: function onChange(e) {
        return _this.setState({
          comment: e.target.value
        });
      }
    }))));
  };

  return EthTableList;
}(React.Component);

export default EthTableList;
EthTableList.contextType = EthContext;