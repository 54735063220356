var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import { FetchWithAuth } from 'digimaker-ui/util';
import i18n from './i18n/i18n';

var ViewPDF =
/** @class */
function (_super) {
  __extends(ViewPDF, _super);

  function ViewPDF(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {
      url: null
    };
    return _this;
  }

  ViewPDF.prototype.viewPdf = function (e) {
    var _this = this;

    e.preventDefault();
    this.setState({
      url: 0
    });
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/pdf/" + this.props.id + "?language=" + this.props.language).then(function (data) {
      if (data.error === false) {
        var url = "/" + data.data;

        _this.setState({
          url: url
        });

        window.open(url, "_blank");
      } else {
        _this.setState({
          url: ''
        });
      }
    }).catch(function (ex) {
      _this.setState({
        url: ''
      });
    });
  };

  ViewPDF.prototype.render = function () {
    var _this = this;

    return React.createElement("span", null, React.createElement("a", {
      href: "#",
      onClick: function onClick(e) {
        return _this.viewPdf(e);
      }
    }, React.createElement("i", {
      className: "fas fa-file-pdf"
    }), " ", i18n.t('View PDF')), this.state.url === 0 && React.createElement("span", {
      className: "loading"
    }), this.state.url === '' && React.createElement("span", null, "Error when generating."));
  };

  return ViewPDF;
}(React.Component);

export default ViewPDF;