import * as React from 'react';
import Moment from 'react-moment';
import './workflow.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import fetch from "isomorphic-fetch";
import {FetchWithAuth} from 'digimaker-ui/util';

export default class WorkflowLog extends React.Component<{ content: any }, { activitylist: any, showDialog: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = { activitylist: '', showDialog: false };

  }

  fetchData() {
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/workflow/activitylist/' + this.props.content.id)
      .then((data) => {
        this.setState({ activitylist: data.data });
      });
  }


  //when init
  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (<div>
      <h3>Activity log</h3>
      {this.state.activitylist && this.state.activitylist.map((activity: any) => {
        return <div className="activity">
          <div className="activity-title">
            <Moment unix format="DD.MM.YYYY HH:mm">{activity.created}</Moment> {/*User*/}
                  {activity.type!='-1'&&<span className={"workflow-status status-" + activity.type}></span>}
                  {activity.version>0&&<span>{/*<Link to={"/version/"+activity.content_id+"/"+activity.version}>*/}v{activity.version}{/*</Link>*/}</span>}
          </div>
          <div className="activity-body"><div dangerouslySetInnerHTML={{__html: activity.comment}}></div></div>
        </div>
      })}
    </div>);
  }
}
