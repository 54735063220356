var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import Select from 'react-select';
import './eth_fieldtype.css';

var Preferance =
/** @class */
function (_super) {
  __extends(Preferance, _super);

  function Preferance(props) {
    var _this = _super.call(this, props) || this;

    var options = [{
      label: 'Norsk',
      value: 'nor-NO'
    }, {
      label: 'English',
      value: 'eng-GB'
    }];
    _this.state = {
      options: options
    };
    return _this;
  }

  Preferance.prototype.render = function () {
    var definition = this.props.definition;
    var identifier = definition.identifier;
    var data = this.props.data;
    var options = this.state.options;
    var defaultValue = options.filter(function (item) {
      return data ? item.value == data.language : false;
    });
    return React.createElement("div", {
      className: "field " + definition.type + " field-" + definition.identifier
    }, definition.name, React.createElement("div", {
      className: "block"
    }, React.createElement("label", null, "Language: "), React.createElement("div", {
      className: "field-value"
    }, this.props.mode == 'edit' && React.createElement(Select, {
      defaultValue: defaultValue,
      name: definition.identifier + '[language]',
      options: options
    }), this.props.mode == 'view' && React.createElement("span", null, defaultValue.length > 0 ? defaultValue[0].label : ''))));
  };

  return Preferance;
}(React.Component);

export default Preferance;