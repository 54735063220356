import * as React from 'react';
import { RouteProps } from 'react-router';
import Form from './Form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {detect} from 'detect-browser';
import { Util } from '../util/Util';
import { EthContext } from 'eth-common/EthContext';

export default class Report extends React.Component<RouteProps, {}> {

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {

    let browser = detect();
    let validBrowser = false;
    
    if( browser ){

      if(window.screen.width < 800){
        validBrowser = false;
      }else{
        if( ["chrome", "firefox", "safari", "edge-chromium"].includes( browser.name ) ){
          validBrowser = true;
        }else if( browser.name == 'edge' ){
          let version = parseInt( browser.version.split('.')[0] );
          if( version >= 80) {
            validBrowser = true;
          }
        }
      }      
    }
    if( !validBrowser ){
      return <div className="alert alert-danger">Your browser{browser&&<span>({browser.name}, {browser.version})</span>} is not supported by the reporting system. <br /><br />Supported browser: Chrome, Firefox, Safari, Edge(version 80 later) <b>desktop</b> version.

      </div>
    }

    let props: any = this.props;
    let id = parseInt(props.match.params.id);
    let user = this.context.user;

    if( !user ){
      return <span>...</span>;
    }

    let formType = Util.getCurrentForm(user);

    return (
      <>{formType && <div className={"report-form report-type-"+formType}>
        <Form id={id} formType={formType} beforeField={() => { }} />
      </div>}
      {!formType && <div className="alert alert-warning">
        Invalid form.
      </div>}
      </>
    );
  }
}

Report.contextType = EthContext;
