import * as React from 'react';
import { RouteProps } from 'react-router';
import Moment from 'react-moment';
import { HashRouter as Router, Route, NavLink, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import i18n from 'eth-common/i18n/i18n';
import fetch from "isomorphic-fetch";
import {FetchWithAuth} from 'digimaker-ui/util';
import { EthContext } from 'eth-common/EthContext';
import ViewPDF from 'eth-common/ViewPDF';
import Level from 'eth-common/Level';
import Modal from 'react-bootstrap/Modal'

export default class History extends React.Component<{}, { list: any, levelContent:any }> {

    constructor(props: any) {
        super(props);
        this.state = { list: '', levelContent:'' };
    }

    fetchContent() {
        //fetch content and set to context
        FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/report/history')
            .then((data) => {
                this.setState({ list: data.data });
            })
    }

    //when init
    componentDidMount() {
        this.fetchContent();
    }


    showLevel( e, content ){
      e.preventDefault();
      this.setState({levelContent: content});
    }

    hideLevel(){
      this.setState({levelContent: ''});
    }

    render() {
        let list = this.state.list;
        if (!list) {
            return <div className="loading"></div>
        }

        let count = list.length;
        return (
            <div className="content-wrapper">
                <div>
                    <div className="right">
                        <Link to="/myreport"><i className="fas fa-arrow-circle-left"></i> {i18n.t("Back")}</Link>
                    </div>
                    <h2>{i18n.t("My previous report")}</h2>
                    {count == 0 && <div className="alert alert-info">{i18n.t("No report found.")}</div>}
                    {count > 0 &&
                        <table className="table">
                            <tr>
                              <th>{i18n.t("Year")}</th>
                                <th>{i18n.t("Report")}</th>
                                <th>{i18n.t("Status")}</th>
                                <th>{i18n.t("Created")}</th>
                                <th>{i18n.t("Updated")}</th>
                                <th></th></tr>
                            {list.map((content: any) => {
                                let url = "view/" + content.id;
                                let date = new Date(content.published * 1000);
                                if( content.report_year==this.context.currentYear ){
                                  return '';
                                }
                                return (<tr>
                                    <td>{content.report_year}</td>
                                    <td><Link to={url}>{content.name}</Link></td>
                                    <td><span className={"workflow-status status-" + content.status}></span></td>
                                    <td><Moment unix format="DD.MM.YYYY HH:mm">{content.published}</Moment></td>
                                    <td><Moment unix format="DD.MM.YYYY HH:mm">{content.modified}</Moment></td>
                                    <td>
                                    <ViewPDF id={content.id} language={this.context.language} /> &nbsp;&nbsp;
                                    <a href="#" onClick={(e=>this.showLevel(e, content))}>{i18n.t('Level assessment')}</a>
                                    </td>
                                </tr>)
                            })}
                        </table>
                    }

                    <Modal size="xl" show={this.state.levelContent} onHide={()=>this.hideLevel()}>
                    <Modal.Header translate="yes" closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Level content={this.state.levelContent} />
                    </Modal.Body>
                  </Modal>
                </div>
            </div>
        )
    }
}

History.contextType = EthContext;
