//render content based on settings/blocks
import * as React from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import {FetchWithAuth} from 'digimaker-ui/util';
import Select from 'react-select'
import './Level2Articles.css';
import { useEffect, useState } from 'react';
import { ExpandableLine } from '../shared/Common';
import { Link } from 'react-router-dom';

export default class Level2Articles extends React.Component<{content:any}, {data:any, selected:any}> {
    constructor(props: any) {
        super(props);
        let hash = window.location.hash;
        let selected:any = [0,0];
        if( hash.length > 1 ){
            let arr = hash.substr(1).split(',');            
            selected = [parseInt(arr[0]), parseInt(arr[1])];
        }
        this.state = {data:'', selected:selected};
      }


    componentDidMount(){
        this.fetchData();
    }

    async fetchData(){
        let data = await FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/content/treemenu/'+this.props.content.id+'?type=folder,article&sortby=priority desc');
        if( data.error === false ){
            this.setState({data: data.data});
        }
    }

    render(){
        let data = this.state.data;
        if(!data){
            return <div className="loading"></div>;
        }

        let category = this.state.selected[0]
        
        let list = data.children[category].children;       

        return <div>
            <h2>{data.name}</h2>
            <div className="text-right">
                <ul className="plain nav-buttons">
                    {data.children.map((folder, index)=>
                    <li onClick={()=>this.setState({selected: [index,0]})} className={index==category?'selected':''}>
                        <a href="javascript:void(0)">{folder.name}</a>
                    </li>)}
                </ul>
            </div>
            <br /><br />
            {list&&<div key={category}>                           
            <Tabs defaultActiveKey={this.state.selected[1]+1}>
            {list.map( (article, index) =>{
                if(article.content_type!='article'){
                    return '';
                }

                return <Tab title={article.content.link?(<Link to={article.content.link}><i style={{fontSize:'0.7rem'}} className="fas fa-link"></i> {article.name}</Link>):article.name} eventKey={index}>     
                    <div className="content-container max-700 content">
                        <br />
                        <TabContent article={article.content} key={article.id+''+index} />                                                
                    </div>           
                </Tab> })}

            </Tabs>
            </div>}

        </div>;
    }

}

const TabContent = (props:{article:any})=>{
    const [related, setRelated] = useState([] as Array<any>);
    const [resources, setResources] = useState([] as Array<any>);
    

    useEffect(()=>{
        let ids:Array<any> = [];
        let relateArticles = props.article.related_articles;        
        if( relateArticles ){
            for( let item of relateArticles ){
                ids.push(item.from_content_id);
            }
        }
        if( ids.length >0 ){
            FetchWithAuth(process.env.REACT_APP_REMOTE_URL+'/content/list/article?cid='+ids.join(',')).then(data=>{
                setRelated(data.data.list);
            });
        }
    }, related);

    useEffect(()=>{
        let ids:Array<any> = [];
        let relatedResources = props.article.resources;        
        if( relatedResources ){
            for( let item of relatedResources ){
                ids.push(item.from_content_id);
            }
        }
        if( ids.length >0 ){
            FetchWithAuth(process.env.REACT_APP_REMOTE_URL+'/content/list/file?cid='+ids.join(',')+'&limit=0').then(data=>{
                let sortedList:Array<any> = [];
                for( let id of ids ){
                    let resource:any = data.data.list.find(item=>item.cid==id);
                    if( resource ){
                        sortedList.push(resource);
                    }
                }
                setResources(sortedList);
            });
        }
    }, resources);

    return <>
    <div className="article-summary" dangerouslySetInnerHTML={{__html:props.article.summary}} />

        <br />
        <div dangerouslySetInnerHTML={{__html:props.article.body}} />

        {props.article.related_articles&&<div>
            {related.map(item=>
                <ExpandableLine title={item.name} body={()=><div dangerouslySetInnerHTML={{__html: item.summary+item.body}}></div>} />
            )}    
        </div>}

        <br />

        {props.article.resources&&<div>
        {props.article.resources_text&&<h3>{props.article.resources_text}: </h3>}

        {resources.map((resource)=>
         <div className="line dmb-expandable">
         <div className="line-header">
          <a style={{display:'block'}} target="_blank" href={resource.path?(process.env.REACT_APP_ASSET_URL+'/'+resource.path):(resource.link)}>{resource.name} <span className="right"><i className="fas fa-download"></i></span></a>
        </div>
        </div>
        )}

        </div>}
    </>;
}
