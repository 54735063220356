import * as React from 'react';
import { RouteProps } from 'react-router';
import Moment from 'react-moment';
import { HashRouter as Router, Route, NavLink, Link, withRouter } from "react-router-dom";
import Auth from '../user/Auth';
import { EthContext } from 'eth-common/EthContext';
import i18n from 'eth-common/i18n/i18n';
import {FetchWithAuth} from 'digimaker-ui/util';


export default class MessageList extends React.Component<{count?:number}, { list:any }> {

  constructor(props: any) {
    super(props);
    this.state = { list:'' };
  }


  componentDidMount() {
    this.fetch();
  }

  fetch(){
    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + '/eth/user/messagelist/'+(this.props.count?this.props.count:'0'))
    .then((data)=>{
      this.setState({list:data.data});
    });
  }

  render() {
      if(!this.state.list){
        return '';
      }

      return <div className="messagelist">
        <h2>{this.props.count?i18n.t('Latest '):''}{i18n.t('Messages')}</h2>
        {this.state.list.list.map((item, index)=>
          <div className="message-item">
            <div><span className="message-subject">{item.subject}</span></div>
            <div className="message-body">
              <div dangerouslySetInnerHTML={{__html:item.content}} />
              {item.attachments&&<div>
                  {item.attachments.split(';').map((attachment)=><span>
                    <a href={process.env.REACT_APP_ASSET_URL+"/"+attachment} target="_blank">
                      <i className="fas fa-paperclip"></i>
                    </a> &nbsp;
                  </span>)}
                </div>}
            </div>
            <div className="message-time">
              <span><Moment unix format="DD.MM.YYYY HH:mm">{item.time}</Moment>
              </span> {i18n.t('from')} &nbsp;
              <span className="message-sender">{item.sender_name}</span>
             </div>
          </div>
      )}</div>
  }
}
