var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import { EthContext } from "./EthContext";
import i18n from "./i18n/i18n";
import { FetchWithAuth } from "digimaker-ui/util";

var Level =
/** @class */
function (_super) {
  __extends(Level, _super);

  function Level(props) {
    var _this = _super.call(this, props) || this;

    _this.print = function () {
      var levels = document.getElementById("levels");
      window["printElement"](levels);
    };

    _this.state = {
      list: "",
      comments: "",
      language: ""
    };
    return _this;
  }

  Level.prototype.componentDidMount = function () {
    this.fetchLevels();
    this.fetchComments(this.props.content.id);
    this.setState({
      language: this.context.language
    });
  };

  Level.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
    if (prevState.language != this.context.language) {
      this.setState({
        language: this.context.language
      });
      this.fetchLevels();
    }
  };

  Level.prototype.fetchLevels = function () {
    var _this = this;

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/report/getlevels/" + this.context.language + "?type=" + this.props.content.content_type + "&calculated=true").then(function (data) {
      _this.setState({
        list: data
      });
    });
  };

  Level.prototype.fetchComments = function (id) {
    var _this = this;

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/report/comment/fetch/" + id).then(function (data) {
      if (data.data.comment) {
        _this.setState({
          comments: JSON.parse(data.data.comment)
        });
      }
    });
  };

  Level.prototype.render = function () {
    var content = this.props.content;
    var list = this.state.list;

    if (!content || !list) {
      return "";
    }

    var comments = this.state.comments;
    var basicLevel = [0, 0];
    var basicLevelComment = [0, 0];
    list.map(function (item, i) {
      item.value = 0;
      var valueBreak = false;
      item.value2 = 0;
      var value2Break = false;
      var reasons = [];
      item.children.map(function (levelGroup, j) {
        var level1Value = 1; //level1 value. eg. 1.A, 1.B, 2.C

        var level1Value2 = 1; //level1 value from editor

        var count = levelGroup.children.length;
        levelGroup.children.map(function (level) {
          var value = 1; //input level

          if (content[level] == 0) {
            value = 0;
          } //comment level


          var comment = comments[level];
          var levelValue = comment && comment["level"] != undefined ? comment["level"] : -1;
          var value2 = levelValue != -1 ? levelValue : value; //calculation

          if (level1Value == 1 && value == 0) {
            level1Value = 0;
          }

          if (level1Value2 == 1 && value2 == 0) {
            level1Value2 = 0;
          } //basic levels


          if (j == 0) {
            if (value == 1) {
              basicLevel[0]++;
            }

            if (value2 == 1) {
              basicLevelComment[0]++;
            }

            basicLevel[1]++;
            basicLevelComment[1]++;
          } //reasons


          if (comment && comment["comment"]) {
            reasons.push(comment["comment"]);
          }
        });

        if (level1Value == 0) {
          valueBreak = true;
        }

        if (!valueBreak && level1Value == 1 && count > 0) {
          item.value = item.value + 1;
        }

        if (level1Value2 == 0) {
          value2Break = true;
        }

        if (!value2Break && level1Value2 == 1 && count > 0) {
          item.value2 = item.value2 + 1;
        }
      });
      item.reasons = reasons;
      list[i] = item;
    });
    console.debug(basicLevel);
    console.debug(basicLevelComment);
    return React.createElement("div", null, React.createElement("div", {
      className: "right"
    }, React.createElement("a", {
      onClick: this.print,
      href: "javascript:void"
    }, React.createElement("i", {
      className: "fa fa-print",
      style: {
        marginRight: "5px"
      }
    }), i18n.t("Print"))), React.createElement("div", {
      id: "levels",
      className: "level-evaluation"
    }, React.createElement("div", {
      style: {
        display: "flex"
      },
      className: "level-header"
    }, React.createElement("span", null, React.createElement("img", {
      src: "/logo-green.svg",
      style: {
        width: "180px"
      }
    })), React.createElement("h2", {
      style: {
        paddingTop: "10px"
      }
    }, i18n.t("TILBAKEMELDING PÅ RAPPORTERING TIL ETISK HANDEL NORGE FOR"), ": ", this.props.content.report_year)), React.createElement("table", {
      className: "table"
    }, React.createElement("tr", null, React.createElement("th", null), React.createElement("th", {
      colSpan: comments ? 1 : 2
    }, React.createElement("h3", null, i18n.t("Vurdering av nivå"))), comments && React.createElement("th", null)), React.createElement("tr", null, React.createElement("th", null, React.createElement("h3", null, i18n.t("Underpunkt"))), React.createElement("th", null, React.createElement("h3", null, i18n.t("Medlem"))), comments && React.createElement("th", null, React.createElement("h3", null, i18n.t("Sekretariat"))), comments && React.createElement("th", null, React.createElement("h3", null, i18n.t("Begrunnelse / kommentarer")))), list.map(function (item) {
      return React.createElement("tr", null, React.createElement("td", null, item.name), React.createElement("td", null, item.value), comments && React.createElement("td", null, item.value2), comments && React.createElement("td", {
        className: "comment-reason"
      }, item.reasons.map(function (item) {
        return React.createElement("div", null, item);
      })));
    }), React.createElement("tr", null, React.createElement("td", null, React.createElement("b", null, i18n.t("Basisnivå"), ":")), React.createElement("td", null, React.createElement("b", null, (100 * basicLevel[0] / basicLevel[1]).toFixed(0), "%", " ", i18n.t("completed"))), comments && React.createElement("td", null, React.createElement("b", null, (100 * basicLevelComment[0] / basicLevelComment[1]).toFixed(0), "% ", i18n.t("completed"))), comments && React.createElement("td", null))), comments && comments["_general"] && React.createElement("div", null, React.createElement("label", null, React.createElement("b", null, i18n.t("Tilbakemelding på rapporten"), ":")), React.createElement("div", {
      style: {
        whiteSpace: "pre-wrap"
      }
    }, comments["_general"]["comment"])), React.createElement("div", null, " ", React.createElement("br", null), React.createElement("i", null, this.state.language === "nor-NO" ? "​​Dette er en tilbakemelding på rapportering i henhold til Etisk handel Norges ulike rapporteringsnivå for aktsomhetsvurderinger. Den skriftlige tilbakemeldingen er basert på egenvurdering av definerte KPIer, svar på definerte spørsmål samt opplastet dokumentasjon. Tilbakemeldingen fra medlemsrådgiver er ment som underlag for videre utvikling av det enkelte medlems aktsomhetsvurderinger. Dersom tilbakemeldingen skal deles med eksterne, skal dette gjøres i samråd med Etisk handel Norge, og tilbakemeldingen skal alltid deles i sin helhet." : "This feedback is based on reporting done in accordance with Ethical Trade Norway’s due diligence assessment levels. The written feedback is based on a self-assessment and defined KPIs as well as answers to specific questions and uploaded documentation. The feedback from the member advisor is intended as a basis for further development of the individual member's due diligence work. If feedback is to be shared with external parties, this must be done in consultation with Ethical Trade Norway, and the feedback must always be shared in its entirety."))));
  };

  return Level;
}(React.Component);

export default Level;
Level.contextType = EthContext;