var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react'; // @ts-ignore

import LoadFields from 'digimaker-ui/LoadFields';
import EthIndicator from '../EthIndicator';
import { EthContext } from '../EthContext';
import './eth_fieldtype.css';
import ReactTooltip from 'react-tooltip';
import FieldRegister from 'digimaker-ui/FieldRegister';
import EthDocumentList from './EthDocumentList';
import EthTableList from './EthTableList';
import OutputH from './OutputH';
import Custom from './Custom';
import Image from './Image';
import Richtext from './Richtext';

var Preview =
/** @class */
function (_super) {
  __extends(Preview, _super);

  function Preview(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {};
    return _this;
  }

  Preview.prototype.componentDidMount = function () {
    FieldRegister.registerComponent('output_h', OutputH);
    FieldRegister.registerComponent('eth_documentlist', EthDocumentList);
    FieldRegister.registerComponent('eth_tablelist', EthTableList);
    FieldRegister.registerComponent('report/step3_a1', Custom);
    FieldRegister.registerComponent('report_public_sector/step3_a1', Custom);
    FieldRegister.registerComponent('report/indicator', Custom);
    FieldRegister.registerComponent('user/preferance', Custom);
    FieldRegister.registerComponent('image', Image);
    FieldRegister.registerComponent('text', Richtext);
  };

  Preview.prototype.afterField = function (fieldDef, data, validation) {
    if (fieldDef.parameters && fieldDef.parameters.has_indicator) {
      return React.createElement(EthIndicator, {
        fromField: fieldDef.identifier,
        parameters: fieldDef.parameters,
        data: data['indicator'],
        mode: 'view',
        validation: validation && validation.fields['indicator'] ? validation.fields['indicator'] : ''
      }); //{this.state.validation?this.state.validation.fields['indicator']:''}
    }
  };

  Preview.prototype.render = function () {
    if (this.props.mode == 'view') {
      return '';
    }

    var definition = this.props.definition;
    var identifier = definition.identifier;
    return React.createElement("div", {
      className: "field preview"
    }, React.createElement("label", null, definition.name), definition.description && React.createElement("span", null, React.createElement("i", {
      className: "icon-info",
      "data-for": identifier + "-description",
      "data-tip": ""
    }), React.createElement(ReactTooltip, {
      id: identifier + '-description',
      html: true,
      effect: "solid",
      place: "right",
      clickable: true,
      multiline: true,
      delayHide: 500,
      className: "tip"
    }, definition.description)), React.createElement(LoadFields, {
      type: this.props.contenttype.split('/')[0],
      mode: "view",
      language: this.context.language,
      data: this.props.formdata,
      afterField: this.afterField
    }));
  };

  return Preview;
}(React.Component);

export default Preview;
Preview.contextType = EthContext;