var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from "react";
import ReactTooltip from "react-tooltip";
import Text from "digimaker-ui/fieldtype/Text";
import { RichEditor } from "../RichEditor";

var Richtext =
/** @class */
function (_super) {
  __extends(Richtext, _super);

  function Richtext(props) {
    return _super.call(this, props) || this;
  }

  Richtext.prototype.componentDidMount = function () {};

  Richtext.prototype.render = function () {
    var params = this.props.definition.parameters;

    if (params && params.multiline) {
      return React.createElement(React.Fragment, null, React.createElement("label", {
        className: "field-label",
        htmlFor: this.props.definition.identifier
      }, this.props.definition.name, this.props.definition.description && React.createElement("i", {
        className: "icon-info",
        "data-for": this.props.definition.identifier + "-desciption",
        "data-tip": ""
      }), this.props.definition.description && React.createElement(ReactTooltip, {
        id: this.props.definition.identifier + "-desciption",
        effect: "solid",
        place: "right",
        html: true,
        clickable: true,
        multiline: true,
        delayHide: 500,
        className: "tip"
      }, this.props.definition.description), ":", " "), React.createElement("div", {
        className: "field-value",
        style: {
          width: this.props.mode == 'edit' ? '50%' : '100%',
          display: 'block'
        }
      }, React.createElement(RichEditor, {
        name: this.props.definition.identifier,
        mode: this.props.mode,
        data: this.props.data
      })));
    } else {
      return React.createElement(Text, __assign({}, this.props));
    }
  };

  return Richtext;
}(React.Component);

export default Richtext;