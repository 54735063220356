import * as React from 'react';
import { RouteProps } from 'react-router';
import Moment from 'react-moment';
import { HashRouter as Router, Route, NavLink, Link, withRouter } from "react-router-dom";
import Auth from '../user/Auth';
import { EthContext } from 'eth-common/EthContext';
import i18n from 'eth-common/i18n/i18n';
import {FetchWithAuth} from 'digimaker-ui/util';
import fetch from "isomorphic-fetch";
import Cookies from 'universal-cookie';
import { Col, Container, Row } from 'react-bootstrap';

const cookies = new Cookies();

export default class Login extends React.Component<{}, { language:any, username: any, password: any, rememberme:boolean, error: string, sending: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = { language:'nor-NO', username: '', password: '', rememberme:false, error: '', sending: false };
  }


  switchLanguage(e, language){
    e.preventDefault();
    this.setState({language: language});
    i18n.changeLanguage(language);
  }

  login(e: any) {
    e.preventDefault();
    this.setState({ sending: true });
    let params = {username: this.state.username, password: this.state.password, rememberme: this.state.rememberme};
    fetch(process.env.REACT_APP_REMOTE_URL + '/auth/auth', 
          {method:'POST', body:JSON.stringify( params )})
      .then(res => {
        this.setState({ sending: false });
        if (res.ok) {
          res.json().then((data)=>{
          cookies.set('refreshToken',data.data.refresh_token);
          window.location.href = "/";
          });
        } else if(res.status==400){
          this.setState({ error: 'Invalid username or password' });
        }else{
          res.text().then(text => {
            this.setState({ error: text });
          });
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  updateUsername(e: any) {
    this.setState({ username: e.target.value });
  }

  updatePassword(e: any) {
    this.setState({ password: e.target.value });
  }

  render() {

    return (
      <div className="login">
      <div>
        <div className="login-language">
          <a href="#" onClick={(e) => { this.switchLanguage(e, 'eng-GB') }} className={this.state.language == "eng-GB" ? 'current-language' : ''} title="English">EN</a>
          &nbsp; | &nbsp;
        <a href="#" onClick={(e) => { this.switchLanguage(e, 'nor-NO') }} className={this.state.language == "nor-NO" ? 'current-language' : ''} title="Norsk">NO</a>
        </div>
        <a href="https://etiskhandel.no/"><div className="logo">
              <img src={process.env.PUBLIC_URL + "/logo_positive.svg"} />
        </div></a>
      </div>
      <div className="main-anonymous">
        <div className="login-body">          
          <h2>{i18n.t("Member page")}</h2>  
          <Container>
          <Row>    
            <Col className="col-6">    
          <form>
            <h3>{i18n.t("Login")}</h3> 
            <div>
              <label>{i18n.t("Username/Email")}: </label>
              <input type="text" className={"form-control " + (this.state.username === '' ? 'required' : '')} onChange={(e) => this.updateUsername(e)} />
            </div>
            <div>
              <label>{i18n.t("Password")}:</label>
              <div>
                <input type="password" className={"form-control " + (this.state.username === '' ? 'required' : '')} onChange={(e) => this.updatePassword(e)} />
              </div>
            </div>
            <div>
              <br />
              <label><input type="checkbox" checked={this.state.rememberme} value="1" onChange={()=>this.setState({rememberme: !this.state.rememberme})} /> {i18n.t("Remember me")}</label>
            </div>

            <div className="block buttons">
              <input type="submit" className="btn btn-primary btn-sm" onClick={(e) => this.login(e)} value={i18n.t("Login") + ""} />
              <br /><br /><Link to="/resetpassword">{i18n.t("Forgot password/New user?")}</Link>
              {this.state.sending && <span className="loading"></span>}
            </div>
            {this.state.error && <div className="block alert alert-warning">{this.state.error}</div>}
          </form>
          </Col>
          <Col className="col-6 login-line">
            <h3>Bli medlem!</h3>
            <div>Har du ingen bruker? Bli medlem av Etisk handel Norge, og nyt godt av alle <a href="https://etiskhandel.no/bli-medlem/#medlemsfordeler">medlemsfordelene</a>
            <br /><br />
            </div>
            <div>
              <a className="btn btn-primary btn-sm" href="https://etiskhandel.no/bli-medlem/">Bli medlem</a>
            </div>
          </Col>
          </Row>
          </Container>
        </div>
      </div>
      </div>
    )
  }
}
